<template>
  <transition
    enter-from-class="scale-0"
    enter-active-class="ease-in transition-medium"
    enter-to-class="scale-1"
    leave-from-class="scale-1"
    leave-active-class="ease-out transition-medium"
    leave-to-class="scale-0"
  >
    <div
      v-if="isOpenKyc"
      id="authentication-modal"
      tabindex="-1"
      aria-hidden="true"
      class="overflow-y-auto overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center"
    >
      <div
        class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-lg max-h-full m-auto"
      >
        <!-- Modal content -->
        <div class="relative bg-shade-1 rounded-xl shadow-xl">
          <button
            @click="closeModal()"
            type="button"
            class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
            data-modal-hide="authentication-modal"
          >
            <img :src="baseUrl.img+'/assets/img/modal/exclude.svg'" alt="" />
            <span class="sr-only">Close modal</span>
          </button>
          <!-- Modal header -->
          <div class="flex items-center justify-center pt-6 pb-1 font-roboto">
            <div class="px-2 sm:px-4">
              <h3 class="text-xl xs:text-2xl font-bold text-gray-900 uppercase">
                KYC
              </h3>
            </div>
          </div>
          <!-- Modal body -->
          <div class="px-4 sm:px-6 lg:px-8">
            <form @submit.prevent="profileUpdate" class="space-y-3">
              <ProfileForm :loading="loading" :isUser="isUser" />
              <div class="py-3">
                <div class="flex justify-between">
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="acknowledge"
                        type="checkbox"
                        value=""
                        class="w-4 h-4 border border-shade-7 rounded bg-shade-1 focus:ring-1 focus:ring-shade-7 text-background-3"
                        required
                      />
                    </div>
                    <label
                      for="acknowledge"
                      class="ms-2 text-sm font-medium text-shade-11 leading-tight"
                      >I acknowledge that I am not a resident of the following states: Idaho, Michigan, Nevada, Washington. I also acknowledge that I am not a resident outside of the United States.</label
                    >
                  </div>
                </div>
                <div class="flex justify-between mt-4">
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="remember"
                        type="checkbox"
                        value=""
                        class="w-4 h-4 border border-shade-7 rounded bg-shade-1 focus:ring-1 focus:ring-shade-7 text-background-3"
                        required
                      />
                    </div>
                    <label
                      for="remember"
                      class="ms-2 text-sm font-medium text-shade-11 leading-tight"
                    >
                      I agree to the
                      <router-link
                        :to="{ path: '/terms-of-use' }"
                        target="_blank"
                      >
                        <span
                          class="font-medium text-secondary-1 cursor-pointer"
                          >Terms of Service</span
                        > </router-link
                      >,
                      <router-link
                        :to="{ path: '/moozi-rules' }"
                        target="_blank"
                      >
                        <span
                          class="font-medium text-secondary-1 cursor-pointer"
                          >Moozi Rules</span
                        ></router-link
                      >, and
                      <router-link :to="{ path: '/privacy' }" target="_blank">
                        <span
                          class="font-medium text-secondary-1 cursor-pointer"
                          >Privacy Policy</span
                        ></router-link
                      >
                    </label>
                  </div>
                </div>
              </div>
              <div class="pb-8 text-center">
                <button
                  type="submit"
                  class="w-full text-primary-1 bg-secondary-3 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <loading-overlay :isLoading="loading" />
  <div
    v-if="isOpenKyc"
    class="fixed inset-0 z-60"
    style="background-color: rgba(0, 0, 0, 0.3)"
  ></div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed } from "vue";
import eventBus from "plugins/event.js";
import { authStore } from "store/auth.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import ProfileForm from "components/profileform/index.vue";
import { useRouter } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
  props: {
    isUser: {
      type: Object,
      required: true,
    },
  },
  components: {
    LoadingOverlay,
    ProfileForm,
  },
  setup(props) {
    const isOpenKyc = ref(false);
    const useAuthStore = authStore();
    const router = useRouter();
    const loading = ref(false);
    const useUtilitiesStore = utilitiesStore();

    const profile_form = computed(() => {
      return useAuthStore.profile_form;
    });

    const set_phone_form = computed(() => {
      return useAuthStore.set_phone_form;
    });

    const selected_country = computed(() => {
      return useAuthStore.selected_country;
    });

    const toggleKyc = () => {
      isOpenKyc.value = !isOpenKyc.value;
    };

    const resetProfileForm = () => {
      if (props.isUser["address"]) {
        return;
      }
      useAuthStore.clearErrorsProfile();
    };

    const closeModal = () => {
      isOpenKyc.value = false;
      useUtilitiesStore.enableScroll();
      useAuthStore.clearErrors();
      resetProfileForm();
      router.replace({});
    };

    const profileUpdate = () => {
      loading.value = true;
      if (set_phone_form.value) {
        useAuthStore.updateProfileFormValue(
          "phone",
          "+" + selected_country.value.dialCode + set_phone_form.value
        );
      } else {
        useAuthStore.updateProfileFormValue("phone", "");
      }
      useAuthStore
        .profileUpdate(profile_form.value)
        .then(async () => {
          loading.value = false;
          await useAuthStore.getUser(true);
          useAuthStore.clearErrors();
          useAuthStore.forceGetUser();
          closeModal();
          //   eventBus.emit("open:myaccount", "isFromKyc");
          router.push({ path: "/account", query: { tab: "verification" } });
        })
        .catch(() => {
          loading.value = false;
        });
    };

    // const openTerms = () => {
    //   eventBus.emit("open:termsofservice");
    // };

    // const openMRules = () => {
    //   eventBus.emit("open:moozirules");
    // };

    // const openPrivacyPolicy = () => {
    //   eventBus.emit("open:privacypolicy");
    // };

    onMounted(async () => {
      eventBus.on("open:kyc", () => {
        useUtilitiesStore.disableScroll();
        toggleKyc();
      });
    });

    onUnmounted(() => {
      eventBus.off("open:kyc");
    });

    return {
      loading,
      isOpenKyc,
      closeModal,
      profileUpdate,
      baseUrl
      //   openTerms,
      //   openMRules,
      //   openPrivacyPolicy,
    };
  },
};
</script>
