<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenTerms"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center"
          >
               <div class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-xl max-h-full m-auto">
                    <!-- Modal content -->
                    <div class="relative bg-shade-1 rounded-xl shadow-xl">
                         <button
                              @click="onDecline()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="baseUrl.img+'/assets/img/modal/exclude.svg'"
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <form
                              @submit.prevent="onAccept"
                              class="flex flex-col divide-y divide-shade-12 items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 font-bold text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div class="pb-4 w-full text-center">

                              </div>

                              <div class="flex w-full items-center justify-center py-8">
                                   <div class="flex justify-center items-center h-5">
                                        <input
                                             id="remember"
                                             type="checkbox"
                                             value=""
                                             class="w-4 h-4 border border-shade-7 rounded bg-shade-1 focus:ring-1 focus:ring-shade-7 text-background-3"
                                             required
                                        />
                                   </div>
                                   <label
                                        for="remember"
                                        class="ms-2 text-base font-light text-black"
                                   >I agree to the
                                        <router-link
                                             :to="{ path: '/terms-of-use' }"
                                             target="_blank"
                                        >
                                             <span class="text-base text-background-3 cursor-pointer">Terms of Service of Moozi.com</span>
                                        </router-link>
                                   </label>
                              </div>
                              <div class="pt-8 text-center w-full font-helveticaLight">
                                   <div class="px-2 flex w-full gap-4">
                                        <button
                                             @click="onDecline()"
                                             class="w-full text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                        >
                                             Decline
                                        </button>
                                        <button
                                             type="submit"
                                             class="w-full bg-secondary-3 text-primary-1 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                        >
                                             Accept
                                        </button>
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenTerms"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.3)"
     ></div>
</template>

    <script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { useRouter, useRoute } from "vue-router";
import { authStore } from "store/auth.js";
import Cookies from "js-cookie";
import { baseUrl } from "config/services.js";

export default {
     components: {},
     setup() {
          const isOpenTerms = ref(false);
          const useUtilitiesStore = utilitiesStore();
          const useAuthStore = authStore();
          const router = useRouter();
          const route = useRoute();

          const toggleTermsConfirmation = () => {
               isOpenTerms.value = !isOpenTerms.value;
          };

          const closeModal = () => {
               isOpenTerms.value = false;
               useUtilitiesStore.enableScroll();
          };

          const onDecline = () => {
               closeModal();
               Cookies.remove("isLoggedOn");
               useAuthStore.logout().then(() => {
                    router.push({ path: "/signin" });
               });
          };

          const onAccept = () => {
                closeModal();
                Cookies.remove("isLoggedOn")
          };

          onMounted(async () => {
               eventBus.on("open:terms", () => {
                    useUtilitiesStore.disableScroll();
                    toggleTermsConfirmation();
               });

               setTimeout(() => {
                if (route.name == "home") {
                  if (Cookies.get("isLoggedOn") == "true") {
                        useUtilitiesStore.disableScroll();
                        toggleTermsConfirmation();
                  }
                }

               }, 500);
          });

          onUnmounted(() => {
               eventBus.off("open:terms");
          });

          return {
               isOpenTerms,
               closeModal,
               onDecline,
               onAccept,
               baseUrl
          };
     },
};
</script>
