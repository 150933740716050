<template>
     <footer class="relative bg-shade-2 text-white py-4 lg:py-8 tracking-wider font-extralight font-inter">
          <div
               class="text-center w-full 3xl:max-w-screen-3xl mx-auto px-8 xs:px-12 sm:px-18 md:px-24 lg:px-28 xl:px-32 mb-6">
               <div
                    class="flex flex-col-reverse lg:flex-row gap-2 lg:gap-8 items-center lg:items-start font-roboto tracking-widest font-bold text-base xl:text-lg">
                    <div class="hidden lg:block flex-initial -mt-26 z-10">
                         <img :src="baseUrl.img + '/assets/img/footer/footer-animal.avif'" alt=""
                              class="w-auto mx-auto" />
                    </div>
                    <div class="flex-initial flex flex-col items-center lg:px-5 2xl:px-0 mt-10 lg:mt-0">
                         <img :src="baseUrl.img + '/assets/img/footer/footer-logo-line-2.svg'" alt=""
                              class="hidden lg:block w-auto w-fit -mb-4" />
                         <img :src="baseUrl.img + '/assets/img/footer/footer-logo.svg'" alt="" class="w-auto w-fit" />
                         <img :src="baseUrl.img + '/assets/img/footer/footer-logo-line-1.svg'" alt=""
                              class="hidden lg:block w-auto w-fit mt-6" />
                    </div>
                    <div
                         class="flex-1 flex flex-col text-center items-center sm:items-end gap-5 lg:text-right space-y-4 mt-5 sm:mt-10 lg:mt-0">
                         <div class="flex flex-col sm:flex-row justify-start gap-5 md:gap-16 lg:gap-8 !mt-0">
                              <div
                                   class="flex flex-col items-center sm:items-start justify-center sm:justify-start w-full sm:w-32 lg:w-40 xl:w-44">
                                   <p
                                        class="tracking-wider text-base sm:text-lg lg:text-xl xl:text-2xl text-center sm:text-start transform ease-in-out transition-all duration-200 text-2xl">
                                        Social Casino
                                   </p>
                                   <a v-if="isUser" @click="goto('redeem')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Redeem
                                   </a>
                                   <a @click="goto('promotion')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Promotions
                                   </a>
                                   <a v-if="isUser" @click="goto('gold-coin-shop')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Get coins
                                   </a>
                                   <a @click="goto('games')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Games
                                   </a>
                              </div>
                              <div
                                   class="flex flex-col items-center sm:items-start justify-center sm:justify-start w-full sm:w-52 xl:w-60">
                                   <p
                                        class="tracking-wider text-base sm:text-lg lg:text-xl xl:text-2xl text-center sm:text-start transform ease-in-out transition-all duration-200 text-2xl">
                                        Support
                                   </p>
                                   <!-- <a
                                        @click="goto('submit-a-request')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max"
                                   >
                                        Contact Us
                                   </a> -->
                                   <a @click="goto('support')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        FAQ
                                   </a>
                                   <a v-if="isUser" @click="goto('code')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Unique Request Code
                                   </a>
                              </div>
                         </div>
                         <div class="flex flex-col sm:flex-row justify-start gap-5 md:gap-16 lg:gap-8 !mt-0">
                              <div
                                   class="flex flex-col items-center sm:items-start justify-center sm:justify-start w-full sm:w-32 lg:w-40 xl:w-44">
                                   <p
                                        class="tracking-wider text-base sm:text-lg lg:text-xl xl:text-2xl text-center sm:text-start transform ease-in-out transition-all duration-200 text-2xl">
                                        Community
                                   </p>
                                   <a @click="redirectTo(links['facebook'])"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Facebook
                                   </a>
                                   <a @click="redirectTo(links['instagram'])"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Instagram
                                   </a>
                                   <a @click="redirectTo(links['x'])"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        X
                                   </a>
                              </div>
                              <div
                                   class="flex flex-col items-center sm:items-start justify-center justify-start w-full sm:w-52 xl:w-60">
                                   <p
                                        class="tracking-wider text-base sm:text-lg lg:text-xl xl:text-2xl text-center sm:text-start transform ease-in-out transition-all duration-200 text-2xl">
                                        About Us
                                   </p>
                                   <a @click="goto('terms-of-use')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Terms of Service
                                   </a>
                                   <a @click="goto('privacy')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Privacy of Policy
                                   </a>
                                   <a @click="goto('sc-coins')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        SC Promotional Rules
                                   </a>
                                   <a @click="goto('player-safety')"
                                        class="cursor-pointer text-xs sm:text-sm md:text-sm xl:text-lg text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-shade-7 hover:text-secondary-3 !font-normal w-max">
                                        Responsible Play
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>
               <div class="mt-10 mb-20 max-w-2xl px-10 text-center mx-auto">
                    <p class="font-helveticaLight tracking-wider font-bold text-xs xl:text-base text-shade-1">This site
                         is operated by Moshy Gaming LLC of 251 Little Falls Drive, Wilmington DE 19808, you can contact
                         us via our 24/7 Online Chat</p>
               </div>
          </div>
     </footer>
</template>
<script>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { authStore } from 'store/auth.js'
import { baseUrl } from 'config/services.js'
import { useScroll } from 'composables/use-scroll.js'
import { useKYC } from 'composables/use-kyc.js';

export default {
     setup() {
          const router = useRouter()
          const { open_kyc_navigation, open_kyc_game_launcher } = useKYC();

          const { scrollTop } = useScroll()

          const links = {
               facebook:
                    'https://www.facebook.com/profile.php?id=61559086152299&mibextid=LQQJ4d',
               x: 'https://x.com/moozigames',
               instagram: 'https://www.instagram.com/moozi.games'
          }

          const useAuthStore = authStore()

          const isUser = computed(() => {
               return useAuthStore.user
          })

          const redirectTo = (path) => {
               window.open(path)
          }

          const goto = (path) => {
               if (path == 'redeem' || path == 'gold-coin-shop') {
                    if (open_kyc_navigation(path == 'redeem' ? 'from_redeem' : 'from_get_coins') == true) {
                         return;
                    }
               }
               else if (path == 'code' && open_kyc_game_launcher() == true) {
                    return
               }
               else {
                    router.push({ path: '/' + path, force: true })
                    scrollTop()
               }
          }

          onMounted(async () => {
               const isExist = document.querySelector("#siteseal");
               if(isExist == null) {
                    var script = document.createElement('script');
                    script.id = 'siteseal';
                    script.type = 'text/javascript';
                    script.src = 'https://seal.godaddy.com/getSeal?sealID=0Nhw28j7hDv7mwkijILineh3JplNfR2aL6zPreIfI5FU6Hr0mzNGybR7515g';
                    document.body.appendChild(script);
               }
          })
          return {
               router,
               goto,
               redirectTo,
               links,
               isUser,
               baseUrl
          }
     }
}
</script>
