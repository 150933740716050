<template>
     <transition
          v-if="isEmailVerified"
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               class="fixed px-5 hover:bottom-0 -bottom-36 xs:-bottom-42 sm:-bottom-58 md:-bottom-72 lg:-bottom-48 right-0 lg:right-20 xl:right-24 2xl:right-28 w-full lg:w-98 xl:w-100 2xl:w-102 h-48 xs:h-56 sm:h-80 md:h-96 lg:h-64 z-[52] transform ease-in-out transition-all duration-500"
          >
               <div class="relative h-full w-full daily-bonus-container py-4 sm:py-6 lg:py-4 px-4 sm:px-8 lg:px-7 shadow-xl rounded-lg md:rounded-2xl">
                    <!-- @click="minimizeTimer()" -->
                    <button
                         type="button"
                         class="close-btn-bonus-timer absolute end-2.5 rounded-lg text-sm ms-auto inline-flex justify-center items-center"
                         data-modal-hide="authentication-modal"
                         style="right: -50px;border-radius: 100px;color: #fff;"
                    >
                         <span class="sr-only">Close modal</span>
                    </button>
                    <div class="flex justify-between items-center w-full">
                         <div class="flex flex-row w-full">
                              <h3 class="w-max text-secondary-3 font-helvetica font-normal whitespace-nowrap tracking-wider text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl leading-xl xs:leading-3xl sm:leading-5xl lg:leading-4xl uppercase">
                                   Daily bonus
                              </h3>
                              <p class="w-full text-changing-color-animation font-roboto font-bold tracking-wider text-center text-sm xs:text-lg sm:text-3xl lg:text-2xl">
                                   {{ difference > 0 ? (hours+':'+minutes+':'+seconds) : "Ready" }}
                              </p>
                         </div>
                    </div>
                    <div v-if="details && dailyBonus" class="h-full w-full flex flex-col justify-end items-start gap-2 sm:gap-3 lg:gap-2">
                         <div class="flex flex-row items-center gap-2 sm:gap-4 lg:gap-2">
                              <img class="w-7 sm:w-8 h-7 sm:h-8" :src="baseUrl.img+'/assets/img/header/GC.svg'" alt="">
                              <h3 class="w-max text-white font-helvetica font-normal whitespace-nowrap tracking-wider text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl leading-xl xs:leading-3xl sm:leading-5xl lg:leading-4xl uppercase">
                                   {{ money(details[dailyBonus['consecutive']]['gold_coins']) }}
                              </h3>
                         </div>
                         <div class="flex flex-row items-center gap-4 sm:gap-8 lg:gap-4">
                              <img class="w-7 sm:w-8 h-7 sm:h-8" :src="baseUrl.img+'/assets/img/header/SC.svg'" alt="">
                              <p class="w-full text-tertiary-3 font-roboto font-bold tracking-wider text-center text-sm xs:text-lg sm:text-3xl lg:text-2xl">
                                   FREE {{ money(details[dailyBonus['consecutive']]['silver_coins']) }}
                              </p>
                         </div>
                         <div
                              @click="() => openDailyBonus()"
                              class="w-max mb-5 xs:mb-8 sm:mb-12 lg:mb-9 rounded-full font-roboto font-extrabold tracking-wider px-10 sm:px-14 md:px-16 lg:px-10 py-3 bg-secondary-3 text-black text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer"
                         >
                              Open
                         </div>
                    </div>
                    <img
                         :src="baseUrl.img+'/assets/img/promotion/bag.png'"
                         alt="chicken"
                         class="bottom-0 right-0 absolute w-4/12 md:w-4/12 xl:w-48"
                    >
               </div>
          </div>
     </transition>
</template>
<script>
import { watch, ref, computed } from "vue";
import { bonusStore } from "store/bonus.js";
import eventBus from "plugins/event.js";
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";
import { authStore } from "store/auth.js";

export default {
     props: ["onClaimStatus"],
     setup(props) {
          const useBonusStore = bonusStore();
          const useAuthStore = authStore();
          const { money } = useFormatter();

          const dailyBonus = ref(null);

          const difference = ref(null);
          const days = ref(0);
          const hours = ref("00");
          const minutes = ref("00");
          const seconds = ref("00");

          // const dailyBonus = computed(() => {
          //      return useBonusStore.dailyBonus;
          // });

          const details = computed(() => {
               return useBonusStore.details;
          });

          const data = computed(() => {
               difference.value = null;
               if (useBonusStore.dailyBonus) {
                    dailyBonus.value = useBonusStore.dailyBonus;
                    difference.value =
                         +new Date(
                              useBonusStore.dailyBonus["next_claim_start"]
                         ) - +new Date(useBonusStore.dailyBonus["now"]);
               }
               return useBonusStore.dailyBonus;
          });

          function countdownTimer() {
               if (difference.value > 0) {
                    useBonusStore.setClaimableDaily(false);
                    const daysComp = Math.floor(
                         difference.value / (1000 * 60 * 60 * 24)
                    );
                    const hoursComp = Math.floor(
                         (difference.value / (1000 * 60 * 60)) % 24
                    );

                    const minsComp = Math.floor(
                         (difference.value / 1000 / 60) % 60
                    );

                    const secsComp = Math.floor((difference.value / 1000) % 60);

                    days.value =
                         daysComp.toString().length == 1
                              ? "0" + daysComp
                              : daysComp;

                    hours.value =
                         hoursComp.toString().length == 1
                              ? "0" + hoursComp
                              : hoursComp;

                    minutes.value =
                         minsComp.toString().length == 1
                              ? "0" + minsComp
                              : minsComp;

                    seconds.value =
                         secsComp.toString().length == 1
                              ? "0" + secsComp
                              : secsComp;

                    difference.value = difference.value - 1000;
               } else {
                    props.onClaimStatus(difference.value <= 0);

                    if (difference.value == null) {
                         useBonusStore.setClaimableDaily(false);
                    } else {
                         useBonusStore.setClaimableDaily(true);
                    }

                    days.value = "00";
                    hours.value = "00";
                    minutes.value = "00";
                    seconds.value = "00";

                    difference.value = 0;

                    clearInterval(interval);
               }
          }

          const interval = () =>
               setInterval(() => {
                    countdownTimer();
               }, 1000);

          const openDailyBonus = () => {
               props.onClaimStatus(difference.value <= 0);
               eventBus.emit("open:dailybonus");
          };

          let x = 0;

          const isEmailVerified = computed(() => {
               return useAuthStore.isEmailVerified;
          });

          watch(data, (newVal) => {
               difference.value = null;
               if (newVal) {
                    difference.value =
                         +new Date(newVal["next_claim_start"]) -
                         +new Date(newVal["now"]);
                    if (x == 0) {
                         x++;
                         clearInterval(interval);
                         interval();
                    }
               }
          });

          return {
               dailyBonus,
               openDailyBonus,
               difference,
               days,
               hours,
               minutes,
               seconds,
               details,
               money,
               baseUrl,
               isEmailVerified
          };
     },
};
</script>

