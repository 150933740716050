<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isWelcomeBonus"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0  flex justify-center items-center"
          >
               <div class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-xl max-h-full tracking-wider m-auto">
                    <!-- Modal content -->
                    <div class="relative welcome-modal-bg rounded-lg px-10 py-5 shadow">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="baseUrl.img+'/assets/img/modal/exclude.svg'"
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <!-- Modal header -->
                         <div class="flex flex-col items-center justify-center w-full px-5 md:px-7 lg:px-0 ">
                              <h1 class="w-full text-white font-helvetica font-medium tracking-wider text-center text-2xl xs:text-3xl sm:text-4xl md:text-5xl xl:text-6xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">
                                   WELCOME BONUS
                              </h1>
                              <p class="text-white font-helveticaLight tracking-wide w-full text-center font-normal text-sm sm:text-lg md:text-base lg:text-sm xl:text-base">
                                   Loyalty pays off! Come back everyday and reap the rewards of being a dedicated player.
                              </p>
                         </div>
                         <!-- Modal body -->
                         <div>
                              <div class="flex justify-center items-center">
                                   <img
                                        class="w-8/12 my-5 pulse2"
                                        :src="baseUrl.img+'/assets/img/modal/gift.png'"
                                        alt=""
                                   >
                              </div>
                              <div class="h-full w-full flex flex-col justify-center items-center gap-1 lg:gap-1 xl:gap-2">
                                   <div class="flex flex-row items-center gap-2 sm:gap-3 lg:gap-2">
                                        <img
                                             class="w-5 sm:w-5 lg:w-5 xl:w-7 h-5 sm:h-5 lg:h-5 xl:h-7"
                                             :src="baseUrl.img+'/assets/img/header/GC.svg'"
                                             alt=""
                                        >
                                        <h3 class="w-max text-white font-helvetica font-normal whitespace-nowrap tracking-wider uppercase text-xl sm:text-2xl lg:text-xl xl:text-3xl leading-xl sm:leading-2xl lg:leading-xl xl:leading-3xl">
                                             {{welcomeBonus ? money(welcomeBonus['gold_coins']) : ''}}
                                        </h3>
                                   </div>
                                   <div class="flex flex-row items-center gap-4 sm:gap-3 lg:gap-3">
                                        <img
                                             class="w-5 sm:w-5 lg:w-5 xl:w-7 h-5 sm:h-5 lg:h-5 xl:h-7"
                                             :src="baseUrl.img+'/assets/img/header/SC.svg'"
                                             alt=""
                                        >
                                        <p class="w-full text-tertiary-3 font-roboto font-bold tracking-wider text-center text-sm sm:text-base lg:text-xs xl:text-xl">
                                             FREE {{welcomeBonus ? money(welcomeBonus['silver_coins']) : ''}}
                                        </p>
                                   </div>
                              </div>
                              <div class="pt-6 text-center z-10 relative">
                                   <div
                                        @click="() => {
                                        if(claimStatus == null) {
                                             onClaim()
                                        }
                                   }"
                                        class="w-full rounded-full font-roboto font-extrabold tracking-wider text-center px-10 py-3 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                                        :class="!claimLoading ?
                                        (claimStatus == null ?
                                             'bg-secondary-3 text-primary-1 cursor-pointer'
                                             : 'bg-gray-190 text-shade-7 cursor-not-allowed' )
                                        : 'bg-secondary-3 text-primary-1 cursor-not-allowed'"
                                   >
                                        {{
                                             !claimLoading ?
                                                  (claimStatus == null ?
                                                       'Claim'
                                                       : claimStatus.label)
                                                  : (claimStatus == null ?
                                                       'Please Wait ...'
                                                       : claimStatus.label)
                                        }}
                                   </div>
                              </div>
                              <img
                                   v-if="claimStatus?.status"
                                   class="w-full left-0 absolute bottom-7"
                                   :src="baseUrl.img+'/assets/img/modal/bonus.gif'"
                                   alt=""
                              >
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isWelcomeBonus"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75);"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed } from "vue";
import eventBus from "plugins/event.js";
import { useRouter } from "vue-router";
import { useFormatter } from "composables/use-formatter.js";
import { authStore } from "store/auth.js";
import { bonusStore } from "store/bonus.js";
import { claimWelcome as claimWelcomeAPI } from "api/bonus.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
     components: {
     },
     setup() {
          const { money } = useFormatter();
          const router = useRouter();
          const useUtilitiesStore = utilitiesStore();

          const claimLoading = ref(false);
          const claimStatus = ref(null);

          const isWelcomeBonus = ref(false);

          const useAuthStore = authStore();
          const useBonusStore = bonusStore();

          const welcomeBonus = computed(() => {
               return useBonusStore.welcomeBonus;
          });

          const toggleWelcomeBonus = () => {
               isWelcomeBonus.value = !isWelcomeBonus.value;
          };

          const closeModal = () => {
               isWelcomeBonus.value = false;
               useUtilitiesStore.enableScroll();
               router.replace({});
          };

          const reloadBalance = async () => {
               try {
                    await useAuthStore.reloadBalance({ type: "gold_coins" });
                    await useAuthStore.reloadBalance({ type: "silver_coins" });
               } catch (error) {
                    console.error("Error gatting balance:", error);
               }
          };

          async function onClaim() {
               claimLoading.value = true;
               await claimWelcomeAPI().then(async (res) => {
                    setTimeout(() => {
                         claimLoading.value = false;
                         claimStatus.value = {
                              label: res.message,
                              status: res.status,
                         };
                         if (res?.status) {
                              useAuthStore.getUser(true);
                              reloadBalance();
                         }
                    }, 2000);
               });
          }

          onMounted(async () => {
               eventBus.on("open:welcomebonus", () => {
                    useUtilitiesStore.disableScroll();
                    toggleWelcomeBonus();
               });
          });

          onUnmounted(() => {
               eventBus.off("open:welcomebonus");
          });

          return {
               isWelcomeBonus,
               closeModal,
               money,
               welcomeBonus,
               onClaim,
               claimLoading,
               claimStatus,
               baseUrl
          };
     },
};
</script>
