<script setup>
import Default from "./modules/default/index.vue";
import Basic from "./modules/auth/index.vue";
import Play from "./modules/play/index.vue";
import { markRaw, ref, watch } from "vue";
import { useRoute } from "vue-router";

const layout = ref();
const route = useRoute();

watch(
     () => route.meta?.layout,
     async (metaLayout) => {
          console.log(metaLayout);
          try {
               const component =
                    metaLayout &&
                    (await import(
                         /* @vite-ignore */ `./modules/${metaLayout}/index.vue`
                    ));
               if(metaLayout == 'basic') {
                    layout.value = markRaw(Basic);
               } else if(metaLayout == 'play') {
                    layout.value = markRaw(Play);
               } else {
                    layout.value = markRaw(component?.default || Default);
               }
          } catch {
               if(metaLayout == 'basic') {
                    layout.value = markRaw(Basic);
               } else if(metaLayout == 'play') {
                    layout.value = markRaw(Play);
               } else {
                    layout.value = markRaw(Default);
               }
          }
     },
     { immediate: true }
);
</script>

<template>
     <component :is="layout"></component>
</template>
